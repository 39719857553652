"use client"

import * as z from "zod";
import {useForm} from "react-hook-form";
import {useState} from "react";
// import { useSearchParams } from "next/navigation";
import {zodResolver} from "@hookform/resolvers/zod";
import Link from "next/link";

import {LoginSchema} from "@/schemas";
import {Input} from "@/components/ui/input";
import {Form, FormControl, FormField, FormItem, FormMessage,} from "@/components/ui/form";
import {Button} from "@/components/ui/button";
import {FormError} from "@/components/form-error";
import {FormSuccess} from "@/components/form-success";
import {Heading} from "@/components/ui/Heading";
import {Paragraph} from "@/components/ui/texts/paragraph";
import {login} from "@/actions/account/login";

import {useRouter} from 'next/navigation'
import {ResponseType} from "@/types/response-types";
import {getSession} from "next-auth/react";


export const LoginForm = () => {
    const router = useRouter()

    // const searchParams = useSearchParams();
    // const callbackUrl = searchParams.get("callbackUrl");
    // const urlError = searchParams.get("error") === "OAuthAccountNotLinked"
    //     ? "Email already in use with different provider!"
    //     : "";

    const [showTwoFactor, setShowTwoFactor] = useState(false);
    const [error, setError] = useState<string | undefined>("");
    const [success, setSuccess] = useState<string | undefined>("");
    const [isPending, setPending] = useState<boolean>(false);

    const form = useForm<z.infer<typeof LoginSchema>>({
        resolver: zodResolver(LoginSchema),
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const onSubmit = (values: z.infer<typeof LoginSchema>) => {
        setError("");
        setSuccess("");
        setPending(true);

        login(values)
            .then((data) => {
                if (data.status === ResponseType.ERROR) {
                    form.reset(undefined, {keepValues: true});
                    if (data.error === "Account password expired") {
                        router.push('/expired-password?email=' + values.email);
                        return
                    }
                    setError(data.error);
                    setPending(false);
                    return;

                }
                // update the client session from the server, it will call the session api internally
                getSession();

                router.push(`/platform`);


                    // todo think the Slot used in FormControl in form.tsx might be a issue
                    // following doesnt work
                    /*const passwordInput = document.getElementById('passwordInput');
                    if (passwordInput) {
                        passwordInput.focus();
                    } else {
                        console.warn("password not found")
                    }

                    or


                     requestAnimationFrame(_ => {
                        form.setFocus("password");
                    console.log("requestAnimationFrame")
                    })
                    */


            })
            .catch((error) => {
                console.error("error: ", error);
                setError("Something went wrong")
                setPending(false);
            })
    };
    
    return (
        <div className="h-full flex flex-col pb-4">
            <Heading
                styleVariant={"subHeadline"}
                typeVariant={"h2"}
                className="text-grey-900 pt-10 px-6">
                Welcome Back
            </Heading>
            <Paragraph
                variant="body2"
                className="text-grey-900 pt-4 px-6">
                Don&#39;t have an account yet?
                <a
                    className="text-[#2C92DD]"
                    href='/register'>
                    &nbsp;Sign up.&nbsp;
                </a>
            </Paragraph>
            <Form {...form}>
                <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="w-full h-full"
                >
                    <div className="flex flex-col w-full px-4 pt-6">
                        {/* {showTwoFactor && (
                            <FormField
                                control={form.control}
                                name="code"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormControl>
                                            <Input
                                                {...field}
                                                disabled={isPending}
                                                placeholder="123456"
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        )} */}
                        {!showTwoFactor && (
                            <>
                                <FormField
                                    control={form.control}
                                    name="email"
                                    render={({ field }) => (
                                        <FormItem className="flex-1 w-full">
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    disabled={isPending}
                                                    placeholder="john.doe@example.com"
                                                    type="email"
                                                    autoFocus
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="password"
                                    render={({ field }) => (
                                        <FormItem className="flex-1 w-full pt-4">
                                            <FormControl>
                                                <Input
                                                    {...field}
                                                    disabled={isPending}
                                                    placeholder="******"
                                                    type="password"
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button
                                    size="sm"
                                    variant="highlightText"
                                    asChild
                                    className="px-0 font-normal"
                                >
                                    <Link
                                        href={{
                                            pathname: '/recover',
                                            query: {email: form.getValues("email")}, // Pass the email as a query parameter
                                        }}>
                                        Forgot password?
                                    </Link>
                                </Button>
                            </>
                        )}
                        <FormError message={error} />
                        <FormSuccess message={success} />
                        <Button
                            disabled={isPending}
                            type="submit"
                            className="w-full mt-4"
                        >
                            {showTwoFactor ? "Confirm" : "Login"}
                        </Button>
                    </div>

                </form>
            </Form>
        </div>

    );
};